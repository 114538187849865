/* winModal.css */
.modal-content {
  background: #d4edda;
  color: #155724;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-content h2 {
  color: #155724;
}

@media (max-width: 600px) {
  .modal-content {
    padding: 15px;
  }
}

@media (max-width: 400px) {
  .modal-content {
    padding: 10px;
  }
}

