body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.App {
  /* text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  padding-top: 80px;
  padding-bottom: 50px;
  overflow-y: scroll;
}

h1 {
  color: black;
  margin-bottom: 20px;
}

/* button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 20px 50px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */
button:hover {
  background-color: #0056b3;
}

.Home {
  padding: 40px 70px;
  background-color:black;
  color: white;
  border-radius: 10px;
}

@media(max-width:767px){
  .App{
    padding-top: 0px;
  }
  .game-container{
    margin-top: 0;
  }
  .button-container{
    margin-top: 0;
  }
  .button_section{
    margin-top: 0;
  }   
  .sudoku-game{
    margin-top: 0;
  }
}