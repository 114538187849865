.game-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  /* height:60vh; */
  margin: 0;
  font-size: 10px;
  margin-top: 20px;
  /* margin-right: 50px; */
  gap:60px;
  /* overflow-y: scroll; */
  
}

.sudoku-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  /* margin-left: 80px; */
  
}

.controls {
  margin-bottom: 50px;
}

.controls select,
.controls button {
  margin: 0 10px;
  padding: 8px 8px;
  font-size: 16px;
}

.sudoku-board {
  display: inline-block;
  border: 2px solid #000;
}

.sudoku-row {
  display: flex;
}

.sudoku-cell {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  border: 1px solid #697194;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
}

.sudoku-cell.selected {
  /* background-color: #e0e0e0; */
  background-color: lightblue;
  color: #000;
}

.sudoku-cell.initial {
  font-weight: bold;
  color: #00008b;
}

/*Make every third row border bottom black */
.sudoku-row:nth-child(3n + 1) .sudoku-cell {
  border-top: 2px solid #000;
}

.sudoku-row:nth-child(3n) .sudoku-cell {
  border-bottom: 2px solid #000;
}

/*Make every third column border right black */
.sudoku-cell:nth-child(3n + 1) {
  border-left: 2px solid #000;
}

.sudoku-cell:nth-child(3n) {
  border-right: 2px solid #000;
}

.sudoku-cell.invalid {
  background-color: #ebaeae;
  color: red;
}

.button-container {
  /* margin-left: 10px; */
  /* padding: 20px; */
  margin-top: 30px;
  /* margin-right: 20px; */
  /* margin-left: 30px; */
}

.top-btn-col {
  display: flex;
  width: 50px;
  height: 50px;
  border: 1px solid rgb(222, 222, 230);
  justify-content: center;
  align-items: center;
  font-size: 30px;
  border-radius: 30px;
  margin: 15px;
  padding: 30px;
  background-color: rgb(222, 222, 230);
  color: black;
}

.top-btn-col:hover {
  background-color: rgb(211, 211, 235);
}

.btn-col {
  display: flex;
  width: 50px;
  height: 50px;
  border: 1px solid rgb(222, 222, 230);
  justify-content: center;
  align-items: center;
  font-size: 50px;
  border-radius: 5px;
  margin: 1px 10px 10px;
  padding: 50px;
  background-color: rgb(222, 222, 230);
  color: rgb(142, 142, 236);
  cursor: pointer;
}

.last-btn-col {
  background-color: rgb(142, 142, 236);
  padding: 20px 20px;
  margin-top: 2px;
  border-radius: 8px;
  border: 1px solid rgb(142, 142, 236);
  font-size: large;
  margin-left: 11px;
  width: 350px;
  color: white;
  cursor: pointer;
}

.btn-col:hover {
  background-color: rgb(211, 211, 235);
}

.btn-row {
  display: flex;
}

.sudoku-board a {
  cursor: pointer;
  display: inline;
  padding: 2px;
}

.game-btn {
  width: 10px;
  height: 10px;
  border: 1px solid rgb(222, 222, 230);
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin: 15px;
  padding: 30px;
  background-color: rgb(186, 186, 197);
  cursor: pointer;
}

.last-btn-col:hover {
  background-color: rgb(193, 193, 214);
}

/* Default (Light) Mode Styling */
body {
  color: #000000;
}

.game-container {
  background-color: #f8f8f8;
}

.sudoku-cell {
  background-color: #ffffff;
  border: 1px solid #000000;
}

/* Dark Mode Styles */
body.dark-mode {
  background-color: black;
}

.game-container.dark-mode {
  background-color: black;
}

.sudoku-cell.dark-mode {
  background-color: #555555;
  border: 1px solid #ffffff;
}

/* Additional styling for modal in dark mode */
.modal-content.dark-mode {
  background-color: #000;
  color: #ffffff;
}

.modal-button {
  width: 250px;
  height: 47px;
  color: white;
  background-color: rgb(142, 142, 236);
  font-size: 17px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid rgb(142, 142, 236);
  font-weight: 600;
  
}


.hint-box {
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 5px;
  z-index: 100;
  color: black; 
  /* margin-bottom: 20px; */
  font-size: 15px;
}


.sudoku-cell.hint {
  background-color: lightblue;
}

.hint-count {
  position: absolute;
  top: -20px;
  width: auto;
  padding: 1px;
  font-size: 20px;
  background-color: blue;
  right: 0;
  color: white;
  border: 1px solid blue;
  border-radius: 50px;
}

.hint-count-deactive {
  position: absolute;
  top: -20px;
  width: auto;
  padding: 1px;
  font-size: 15px;
  background-color: gray;
  right: 0;
  color: black;
  border: 1px solid gray;
  border-radius: 50px;
}

.button_section {
  margin-top: 30px;
}

.notes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  font-size: 8px;
  color: rgb(100, 97, 97);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
  
}

.notes span {
  display: inline-block;
}

/* Tooltip CSS */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: #333; */
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 10;
  opacity: 0.9;
  visibility: visible;
}

/* Responsive CSS */
@media (max-width: 905px) {
  .game-container {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .sudoku-game {
    margin-top: 20px;
  }

  .sudoku-cell {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }

  .button-container {
    margin-top: 20px;
    padding: 10px;
  }

  .top-btn-col,
  .btn-col {
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 20px;
  }

  .last-btn-col {
    width: auto;
    padding: 10px 20px;
    font-size: 16px;
  }

  .modal-button {
    /* width: auto; */
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .sudoku-cell {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .top-btn-col,
  .btn-col {
    width: 30px;
    height: 30px;
    font-size: 16px;
    padding: 15px;
  }

  .last-btn-col {
    font-size: 14px;
  }

  .modal-button {
    font-size: 14px;
  }
}

.btn-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  padding: 0px;
  background-color: #f0f0f0;
}

.btn-head span {
  margin-right: 20px;
  font-size: 16px;
  color: black;
}

@media (max-width: 767px) {
  .controls {
    margin-bottom: 20px;
  }
  .game-container{
    gap:5px;
  }
}

@media (max-width: 480px) {
  .btn-head span {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .btn-row {
    justify-content: center;
  }

  .top-btn-col {
    width: 40px;
    height: 40px;
    margin: 5px;
    font-size: 18px;
  }

  .hint-count,
  .hint-count-deactive {
    /* width: 20px; */
    /* height: 20px; */
    font-size: 10px;
    top:17%
  }
  .controls select, .controls button {
    font-size: 12px;
  }
}

@media (max-width: 260px) {
  .game-container {
    flex-direction: column;
    height: auto;
    padding: 10px;
    margin: 0 auto;
  }

  .sudoku-cell {
    width: 20px;
    height: 20px;
    font-size: 8px;
  }

  .sum-label {
    font-size: 6px;
    top: 1px;
  }

  .btn-row {
    /* flex-direction: column; */
    align-items: center;
  }

  .top-btn-col {
    width: 20px;
    height: 20px;
    margin: 2px;
    font-size: 10px;
  }

  .hint-count,
  .hint-count-deactive {
    width: 5px;
    height: 5px;
    font-size: 6px;
  }
}

.highlighted {
  background-color: #e0e0e0;
}

