.footer_sec{
    background: #fff;
    min-height: 50px;
    display: flex; 
    /* display: block; */
    align-items: center;
    justify-content: center;
    box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 9999;
}
.footer_sec p{
    margin: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;

}
.footer_sec p span{
    color:#BF1F26;
    font-weight: bold !important;
}
.footer_text{
      display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
  .footer_sec {
    display: none;
  }
}
