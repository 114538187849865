 /* .modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}  */

/* modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* background-color: black; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-view {
  background: white;
  padding: 25px;
  border-radius: 8px;
  position: relative;
  /* max-width: 90%; */
  /* width: 100%; */
  /* max-width: 350px;  */
  /* box-sizing: border-box; */
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

@media (max-width: 600px) {
  .modal-content {
    padding: 15px;
  }

  .modal-close {
    font-size: 1.2em;
  }
}

@media (max-width: 400px) {
  .modal-content {
    padding: 10px;
  }

  .modal-close {
    font-size: 1em;
  }
}

